<template>
  <ClickOutsideDetector @clickOutside="showFireworks = false">
    <div
      style="height: 100%; position: relative;z-index: 1000"
      class="d-flex flex-column primary-colour align-items-center text-center justify-content-center pt-4 pb-5"
    >
      <VueFireworks
        v-if="showFireworks"
        :box-height="400"
        :box-width="200"
        style="position:absolute; top: 0; left: 0;width: 100%;"
        :class="{'dvh': safari === false, 'noDvh': safari }"
        :duration="500"
      />
      <div style="z-index: 100">
        <i class="fa fa-party fa-3x" />
        <div class="fontSizing backgroundConfetti">
          CONGRATULATIONS!!
        </div>
        <div
          v-if="immediatePayment"
          class="backgroundConfetti"
          style="font-size: clamp(1rem, 2vw, 1.3rem)"
        >
          YOU'RE IN THE DRAW
        </div>
        <div
          v-else
          class="backgroundConfetti"
          style="font-size: clamp(1rem, 2vw, 1.3rem)"
        >
          YOU'VE SIGNED UP
        </div>
      </div>
      <div class="divider" />
      <div style="z-index: 100">
        <div
          v-if="immediatePayment"
          style="font-size: clamp(1.2rem, 2vw, 1.8rem)"
          class="text-white backgroundConfetti"
        >
          YOUR FIRST DRAW IS
        </div>
        <div
          v-else
          style="font-size: clamp(1rem, 1.8vw, 1.6rem)"
          class="text-white backgroundConfetti"
        >
          AFTER YOUR PAYMENT ON 1ST OF NEXT MONTH, YOUR FIRST DRAW WILL BE ON
        </div>
        <div class="fontSizing backgroundConfetti">
          {{ nextDrawDate }}
        </div>
      </div>
      <div class="divider" />
      <div style="z-index: 100">
        <div class="backgroundConfetti" style="font-size: clamp(1rem, 2vw, 1.3rem)">
          THANKS FOR BEING A
        </div>
        <div class="backgroundConfetti" style="font-size: clamp(1rem, 2vw, 1.3rem)">
          GRASSROOTS HERO
        </div>
      </div>
    </div>
  </ClickOutsideDetector>
</template>

<script>
import baseService from '@/api-services/base.service'
import { mapGetters } from 'vuex'
import ClickOutsideDetector from '@/components/UI/clickOutsideDetector.vue'
import VueFireworks from 'vue-damp-fireworks'

export default {
  name: 'SuccessfulPaymentMessage',
  components: {
    ClickOutsideDetector, VueFireworks
  },
  data () {
    return {
      nextDrawDate: '',
      showFireworks: true
    }
  },
  computed: {
    ...mapGetters(['club', 'userData', 'safari']),
    immediatePayment () {
      return this.$route.query.imp === "0"
    }
  },
  created () {
    this.getDraws()
    setTimeout(() => {
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
    }, 5000)
  },
  methods: {
    getDraws () {
      baseService.getDrawsThisMonth(this.userData.access_token).then((res) => {
        const nextDrawDate = this.immediatePayment ? res.data.NextDraw : res.data.FirstDrawNextMonth
        this.nextDrawDate = new Date(nextDrawDate).toLocaleString('default', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })
      })
    }
  }
}
</script>

<style scoped>
.divider {
  border: 1px solid #fff;
  width: 100%;
  margin: 20px 0;
}
.starBackground {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fontSizing {
  font-size: clamp(1rem, 2vw, 3rem);
}
.backgroundConfetti {
  padding: 5px;
  background: var(--bg-color);
}
@media (max-width: 576px) {
  .fontSizing {
    font-size: clamp(1.9rem, 2.5vw, 3.5rem);
  }
}
.dvh {
  height: 100dvh;
}
.noDvh {
  height: 100vh;
}
</style>
