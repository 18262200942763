<template>
  <div>
    <!-- step 1: WHEN OT START PAYING -->
    <WhenToStartPaying
      v-show="costStep === 1"
      @nextCostStep="payment($event)"
    />

    <!-- 2: trust payments checkout-->
    <TrustPaymentCheckout
      v-show="costStep === 2" 
      :immediate-payment="immediatePayment"
    />
  </div>
</template>

<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
import WhenToStartPaying from './whenToStartPaying.vue'
import TrustPaymentCheckout from './trustPaymentCheckout.vue'
import { mapGetters } from 'vuex'
import basketService from '@/api-services/basket.service'
import { API_URL, LIVE_STATUS } from '@/common/config'

export default {
  name: 'Cost',
  components: { WhenToStartPaying, TrustPaymentCheckout },
  data () {
    return {
      costStep: 1,
      errMsg: '',
      paymentOption: -1,
      immediatePayment: false
    }
  },
  computed: {
    ...mapGetters(['currentBasket', 'userData', 'club']),
    currentBasketIds () {
      let ids = ''
      // secure payments only lets alphanumeric values be stored in JWT so each id will be split by a lowercase X and then processed backend
      this.currentBasket.filter(c => c.BasketOrdersId !== undefined).forEach(c => { ids += `x${c.BasketOrdersId}` })
      return ids
    }
  },
  created () {
    window.test = this
  },
  methods: {
    payment (paymentOption) {
      try {
        this.immediatePayment = paymentOption === 0
        basketService.getJwtToken(this.currentBasket.length, this.currentBasketIds, this.immediatePayment, this.club.ClubId, this.userData.crd, this.userData.access_token, this.club.BoostAmount).then((res) => {
          this.costStep = 2
          var scriptTag = '(function() { var st = SecureTrading({ jwt: "' + res.data + '", '
          scriptTag += 'animatedCard: true, livestatus: ' + LIVE_STATUS + ', panIcon: true, submitOnSuccess: false, submitCallback: process, translations : { "Pay": "PAY", "Card number": "CARD NUMBER", "Card number": "CARD NUMBER" , "Expiration date": "EXPIRATION DATE" , "Security code": "SECURITY CODE"  }, '
          scriptTag += 'styles: { "border-radius-input" : "0px", "border-color-input" : "#ffffff", "border-size-input" : "1px", "space-outset-body" : "5px", "space-outset-input" : "1px", "font-size-label": "14px", "color-input" : "#ffffff", "background-color-input" : "#000000", "background-color-body" : "#000000", "color-label" : "#1FFC35" }}); '
          scriptTag += 'st.Components(); '
          scriptTag += '  function process(data) { '
          scriptTag += '    var xhttp = new XMLHttpRequest(); '
          scriptTag += '    xhttp.onreadystatechange = function() { '
          scriptTag += '      if (this.readyState == 4) { '
          scriptTag += '        if (this.status == 200) { '
          scriptTag += '          window.test.checkOutDone(1, ""); '
          scriptTag += '        } else { '
          scriptTag += '          var err = eval("(" + this.responseText + ")");'
          scriptTag += '          window.test.checkOutDone(2, err.Message); '
          scriptTag += '        }; '
          scriptTag += '      }; '
          scriptTag += '    }; '
          scriptTag += '    xhttp.open("POST", "' + API_URL + '/api/game/transaction", true); '
          scriptTag += '    xhttp.setRequestHeader("Content-type", "application/json"); '
          scriptTag += '    xhttp.setRequestHeader("Authorization", "Bearer ' + this.userData.access_token + '"); '
          scriptTag += '    xhttp.send(data.jwt); '
          scriptTag += '  }; '
          scriptTag += '})(); '
          var g = document.createElement('script')
          g.type = 'text/javascript'
          g.async = true
          var s = document.getElementsByTagName('script')[0]
          g.text = scriptTag
          s.parentElement.insertBefore(g, s)
        })
      }
      catch (e) {
        alert(e)
      }
    },
    checkOutDone (type, msg) {
      basketService.refreshCredits(this.userData.access_token).then((res) => {
        res.data.access_token = this.userData.access_token
        this.$store.commit('storeUserData', res.data)
      }).catch(() => {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    
      if (type === 1) {
        this.$store.commit('storeId', 0)
        this.$store.commit('storeHero', 0)
        this.$store.commit('storeSport', 0)
        this.$store.commit('storeColour', '#e1e1e1')
        this.$store.commit('storeNumber', 0)
        this.$store.commit('storeFirstInitial', '')
        this.$store.commit('storeSecondInitial', '')
        this.$store.commit('clearBasket')
        this.$emit('nextPage', { success: true, message: '', immediatePayment: this.immediatePayment })
      } else if (type === 2) {
        this.$emit('nextPage', { success: false, message: msg, immediatePayment: this.immediatePayment })
      }
    }
  }
}
</script>
