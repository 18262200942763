<template>
  <div v-if="showPage">
    <div class="primary-colour my-3 my-xl-4 text-center" style="font-size: 0.9rem;margin-top:30px !important;margin-bottom: 20px !important;">
      PAYMENT INFORMATION
    </div>
    <div v-if="canEnterToday">
      <div
        v-for="(option, i) in options"
        v-show="option.index === 0"
        :key="i"
        class="initialOption p-2 p-lg-3 mb-2 mb-lg-3 text-center cp"
        style="border-radius: 5px;margin-bottom: 30px !important;"
        @mouseover="hoveringOption = option"
        @mouseleave="hoveringOption = {}"
        @click="selectedOption = option"
      >
        <div style="font-size: 0.9rem;margin-bottom:20px;" class="font-weight-light">
          {{ option.first }}
        </div>
        <div style="font-size: 0.9rem;" class="font-weight-light">
          {{ option.second }}
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(option, i) in options"
        v-show="option.index === 1"
        :key="i"
        class="initialOption p-2 p-lg-3 mb-2 mb-lg-3 text-center cp"
        style="border-radius: 5px;margin-bottom: 30px !important;"
        @mouseover="hoveringOption = option"
        @mouseleave="hoveringOption = {}"
        @click="selectedOption = option"
      >
        <div style="font-size: 0.9rem;margin-bottom:20px;" class="font-weight-light">
          {{ option.first }}
        </div>
        <div style="font-size: 0.9rem;" class="font-weight-light">
          {{ option.second }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <mdb-btn
        v-if="selectedOption.index !== -1"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="$emit('nextCostStep', selectedOption.index)"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'WhenToStartPaying',
  data () {
    return {
      selectedOption: { index: -1 },
      hoveringOption: {},
      draws: {
        DrawsRemaining: 1
      },
      firstDrawNextMonth: null,
      nextDraw: null,
      showPage: false,
      first: '',
      canEnterToday: true
    }
  },
  computed: {
    ...mapGetters(['currentBasket', 'userData', 'club']),
    basketCost () {
      return this.currentBasket.length * (5 + this.club.BoostAmount)
    },
    immediateFirstSentence () {
      var cost = this.currentBasket.length * (5 + this.club.BoostAmount)
      var text = 'Your first payment of £' + cost + ' is due immediately and you will be entered into the next'
      //if (this.userData.crd > 0) {
        //if (this.userData.crd >= this.currentBasket.length) {
          //if (this.userData.crd >= this.currentBasket.length) {
            //if (this.currentBasket.length === 1) {
              //text = 'Due to having credits, you have a free entry. We will now enter you into the next'  
            //} else {
              //text = 'Due to having credits, your entries, will be free. We will now enter you into the next'  
            //}
          //} else if (this.userData.crd === 1) {
            //var x = (this.currentBasket.length - 1) * (5 + this.club.BoostAmount)
            //text = 'Due to having credits you have one free entry. For your remaining entries you will be charged £' + x + '. You will now be entered into the next'  
          //}
        //}
        //if (this.userData.crd < this.currentBasket.length) {
          //var y = (this.currentBasket.length) * (5 + this.club.BoostAmount) - (this.userData.crd * (5 + this.club.BoostAmount))
          //text = 'Due to having credits, you will only be charged £' + y + ' and will be immediately entered into the next'  
        //}
      //}
      return text
    },
    immediateSecondSentence () {
      var regularPaymentCost =  5 + this.club.BoostAmount
      var text =  'On the first of each following draw month you will be charged £' +  regularPaymentCost + '.'
      //if (this.userData.crd > 0) {
        //text = 'On the first of each following draw month, you will be charged £' + regularPaymentCost + ' per hero unless you have any additional credits remaining.'
      //}
      return text
    },
    nextMonthSentence () {
      var cost = this.currentBasket.length * (5 + this.club.BoostAmount)
      var text = 'As we have already made draws this month your first grassroots lottery payment will be due on the first of next month at a cost of £' + cost + ' for your entries.'
      //if (this.userData.crd > 0) {
        //if (this.userData.crd >= this.currentBasket.length) {
          //if (this.userData.crd > 1) {
            //if (this.currentBasket.length === 1) {
              //text = 'Due to having credits, your entry, starting next month, will be free.'  
            //} else {
              //text = 'Due to having credits, your entries, starting next month, will be free.'  
            //}
          //} else {
            //text = 'Due to having credits, you will have a free entry, starting next month.'  
          //}
        //}
        //if (this.userData.crd < this.currentBasket.length) {
          //var y = (this.currentBasket.length) * (5 + this.club.BoostAmount) - (this.userData.crd * (5 + this.club.BoostAmount))
          //text = 'Due to having credits, you will only be charged £' + y + ' next month.'  
        //}
      //}
      return text
    },
    options () {
      const option = [
        {
          index: 0,
          title: 'Immediately',
          first: `${this.immediateFirstSentence} ${this.draws.DrawsRemaining === 1 ? ' Grassroots Lottery draw' : this.draws.DrawsRemaining + ' Grassroots Lottery draws'}, starting ` + this.nextDraw +  `.`,
          second: `${this.immediateSecondSentence}`
        },
        {
          index: 1,
          title: 'From Next Draw Month',
          first: `${this.nextMonthSentence}`,
          second: `Your first draw will be on ${this.firstDrawNextMonth}.`
        }
      ]
      return option
    }
  },
  created () {
    this.showPage = false
    this.getDraws()
  },
  methods: {
    getDraws () {
      baseService.getDrawsThisMonth(this.userData.access_token).then((res) => {
        this.draws = res.data
        if (this.draws.DrawsRemaining === 0) {
          this.$emit('nextCostStep', 1)
          return
        }
        const fdnm = new Date(this.draws.FirstDrawNextMonth)
        const nd = new Date(this.draws.NextDraw)
        this.firstDrawNextMonth = fdnm.toLocaleString('default', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })
        this.nextDraw = nd.toLocaleString('default', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })
        this.showPage = true
        if (!this.draws.DrawnThisMonth) {
          this.canEnterToday = true
          this.selectedOption.index = 0;
        } else {
          this.canEnterToday = false
          this.selectedOption.index = 1;
        }
      })
    }
  }
}
</script>
