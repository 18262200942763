<template>
  <mdb-input
    :value="search"
    type="text"
    style="width:100%;"
    class="m-0 greenForm"
    :placeholder="placeHolder"
    size="sm"
    @input="e => search = e"
  />
</template>

<script>

export default {
  name: 'SearchBar',
  components: {
  },
  props: {
    searchTriggersAPICall: {
      default: false,
      type: Boolean
    },
    placeHolder: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      search: '',
      delayAPITimer: null
    }
  },
  watch: {
    search () {
      if (!this.searchTriggersAPICall) {
        this.$emit('search', this.search.toLowerCase())
      } else {
        const debouncer = this.debounce()
        debouncer()
      }
    }
  },
  methods: {
    clearEntered () {
      this.search = ''
    },
    processChange () {
      this.$emit('search', this.search.toLowerCase())
    },
    debounce () {
      const si = this
      return function () {
        clearTimeout(si.delayAPITimer)
        si.delayAPITimer = setTimeout(() => {
          si.processChange()
        }, 1000)
      }
    }
  },
  beforeUnmount () {
    this.$emit('search', '')
  }
}
</script>

<style lang="scss" scoped>
.box {
  position:relative;
}
input[type=text]:focus:not([readonly]) {
  box-shadow: none;
  border: 3px solid var(--pr-color);
}
.input {
  height: 40px;
  width: 40px;
  background: none;
  border: 3px solid var(--pr-color);
  border-radius: 50px;
  box-sizing: border-box;
  background: transparent;
  color: #555;
  outline: none;
  transition: .5s;
  transform-origin: right;
  font-size: 14px;
  letter-spacing: 1px;
  // color: transparent;
  color: #555;
}
.paddingOnFocus {
  padding: 0 30px;
}
input:focus {
  transform-origin: right;
  border-radius: 20px;
}

i.question, i.clear {
  position: absolute;
  top: 32%;
  font-size: 15px;
  color: #555;
  transition: .2s;
}
i.question {
  left: 12px;
}
i.clear {
  right: 14px;
}
.fade {
  opacity: 0;
  transition: .8s ease;
}
.fade.fade-in {
  opacity: 1;
  transition: .8s ease;
}
.widthNoFocus {
  width: 40px;
}

@media (max-width: 768px) {
  input[type=text]:focus:not([readonly]) {
    border: 2px solid var(--pr-color);
  }
  .input {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pr-color);
    font-size: 12px;
  }
  i.question, i.clear {
    top: 30%;
    font-size: 13px;
  }
  i.clear {
    right: 12px;
  }
  i.question {
    left: 9px;
  }
  .paddingOnFocus {
    padding: 0 25px 0 25px;
  }
}
</style>
