<template>
  <div
    class="d-flex align-items-center flex-column text-center mt-4"
    style="height: 100%;width:100%;"
  >
    <span>YOUR CLUB HAS ASKED THAT YOU PURCHASE AT LEAST {{ userData.tik }} TICKETS BEFORE CHECKING OUT.
      THANK YOU FOR YOUR SUPPORT.
    </span>
    <mdb-btn
      class="btn primary-btn btn-right btn-outline btn-radius btn-sm btn-md mt-3"
      @click="addAnother()"
    >
      ADD ANOTHER HERO
    </mdb-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MinTickets',
  computed: {
    ...mapGetters([
      'userData',
      'club'
    ])
  },
  methods: {
    addAnother () {
      this.$store.commit('storeClub', { ClubId: 0 })
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
    }
  }
}
</script>
