<template>
  <div style="height: 100%;" class="d-flex flex-column">
    <!-- CLUB SEARCH -->
    <div class="d-flex flex-column flex-grow-0">
      <div class="mt-4 mb-2 text-center" style="font-size: 0.9rem;">
        Search for your club below, or select our partner charity. If your club is not listed, nominate them 
        <a
          href="https://play.grassrootslottery.co.uk/sign_up_your_club"
        >
          <span style="color:white !important">here</span>
        </a>.
      </div>
      <div style="position:relative; width: 100%">
        <div class="d-flex align-items-center">
          <mdb-icon v-if="!loading && !listOpen" icon="search" />
          <i v-if="loading" class="fa fa-spinner fa-pulse" />
          <i
            v-if="listOpen && !loading"
            class="fa fa-times cp" 
            @click="listOpen = false"
          />
          <SearchInput
            class="ml-3"
            :search-triggers-api-call="true"
            place-holder="Search Club Or Charity"
            @search="assignSearch"
          />
        </div>
        <div
          v-if="listOpen && !loading"
          class="typeAheadResults"
        >
          <ul>
            <li
              v-for="(club, i) in clubs"
              :key="i"
              :class="{'selectedClub': selectedClubId === club.ClubId}"
              @click="selectClub(club)"
            >
              <img
                v-if="club.ClubLogoURL"
                :src="club.ClubLogoURL"
                class="mr-2"
              >
              <span>{{ club.Name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <CharityOrClubLogoContainer />

    <!-- NEXT BUTTON -->
    <div
      v-if="currentBasket.length > 0 && (currentBasket.length < club.MinTickets)"
      class="d-flex justify-content-center mt-3"
    >
      <mdb-btn
        v-if="!noClubSelected"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="errorAlert()"
      >
        NEXT
      </mdb-btn>
    </div>
    <div
      v-else
      class="d-flex justify-content-center mt-3"
    >
      <mdb-btn
        v-if="!noClubSelected"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="$emit('nextPage')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/searchInput.vue'
import basketService from '@/api-services/basket.service'
import { mapGetters } from 'vuex'
import CharityOrClubLogoContainer from './charityOrClubLogoContainer.vue'
export default {
  name: 'SelectClubOrCharity',
  components: { SearchInput, CharityOrClubLogoContainer },
  data () {
    return {
      clubSearch: '',
      clubs: [],
      loading: false,
      listOpen: false
    }
  },
  computed: {
    ...mapGetters(['club', 'clubUrl', 'currentBasket']),
    selectedClubId () { return this.club.ClubId },
    noClubSelected () { return this.selectedClubId === 0 || this.selectedClubId === -1 }
  },
  watch: {
    clubSearch () {
      if (this.clubSearch !== '' && this.clubSearch.length > 2) {
        this.getClubs()
      }
    }
  },
  created () {
    this.setDefaultClub(this.clubUrl)
  },
  methods: {
    async setDefaultClub (clubUrl) {
      if (clubUrl !== '' && clubUrl !== undefined) {
        try {
          const res = await basketService.getClub(clubUrl)
          this.selectClub(res.data)
        } catch {
          //could not find club from URL
        }
      }
    },
    async getClubs () {
      this.loading = true
      this.listOpen = false
      this.clubs = []
      try {
        const res = await basketService.getClubs(this.clubSearch)
        this.clubs = res.data.length > 0 ? res.data : [{ ClubId: -1, Name: 'No Results' }]
        this.listOpen = true
      } catch {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    assignSearch (search) {
      this.clubSearch = search
    },
    selectClub (club) {
      this.listOpen = false
      this.$store.commit('storeClubUrl', club.ClubURL)
      this.$store.commit('storeClub', club)
    },
    errorAlert () {
      this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorMessage: "YOUR CLUB HAS ASKED THAT YOU PURCHASE AT LEAST " + this.club.MinTickets + " TICKETS BEFORE CHECKING OUT. THANK YOU FOR YOUR SUPPORT." })
    }
  }
}
</script>

<style>

.typeAheadResults {
  display: flex;
  background: var(--bg-color);
  width: 100%;
  position: absolute;
  justify-content: center;
  z-index: 1;
}
.typeAheadResults ul {
  width: 100%;
  padding: 0px;
}
.typeAheadResults li {
  padding: 5px;
  border-bottom: 1px dotted #fff;
  display: flex;
  align-items: center;
}
.typeAheadResults li:hover, .selectedClub {
  background-color: rgba(221, 221, 221, .5);
  cursor: pointer;
}
.typeAheadResults img {
  width: 30px;
  border-radius: 50%;
}
.typeAheadResults li span {
  display: inline-block;
}

</style>
